import Script from 'next/script'

export const FontAwesome: React.FC = () => {
  return (
    <>
      <Script
        src='https://kit.fontawesome.com/a1d2f5c4aa.js'
        strategy='lazyOnload'
        crossOrigin='anonymous'
      />
    </>
  )
}
