import Link from 'next/link'
import { css, keyframes } from '@emotion/react'
import { Button, Typography } from '@mui/material'

type Props = {
  url: string
  label: string
  micro: string
}

export const CVButton: React.FC<Props> = ({ url, label, micro }) => {
  return (
    <div css={styles.container}>
      <Typography variant='h6' css={styles.micro} color='primary'>
        {micro}
      </Typography>
      <Link href={url} passHref>
        <a data-prefetch>
          <Button css={styles.button}>
            {label}
            <span className='arrow' css={styles.arrow}></span>
          </Button>
        </a>
      </Link>
    </div>
  )
}

const arrowMotion = keyframes`
    from {
      left:0;
      opacity: 1;
    }
    30% {
      left: 30px;
      opacity: 0;
    }
    50% {
      left: -30px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  `

const styles = {
  container: css`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `,
  micro: css`
    font-size: 0.7rem;
    font-weight: 900;
    letter-spacing: 0.2rem;
    text-align: center;
    margin-bottom: 5px;
    @media (min-width: 960px) {
      font-size: 1.2rem;
      letter-spacing: 0.4rem;
    }
  `,
  button: css`
    height: 60px;
    width: 80vw;
    color: white;
    background: linear-gradient(90deg, #ff4478 0%, #ffa300 100%);
    border: 1px solid;
    border-radius: 30px;
    font-family: 'Noto sans JP', sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    transition: background-color 0.6s ease-in-out;

    &:hover {
    }
    &:hover .arrow:after {
      animation: ${arrowMotion} 0.7s ease-in-out infinite;
    }
    @media (min-width: 960px) {
      width: 600px;
      height: 80px;
      font-size: 1.5rem;
      border-radius: 80px;
    }
  `,
  arrow: css`
    position: relative;
    border: 1.5px solid;
    border-radius: 50%;
    margin-left: 20px;
    width: 30px;
    height: 30px;
    &:after {
      content: '\\f054';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      font-size: 13px;
      display: block;
      top: 4px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.5s ease;
    }
    @media (min-width: 960px) {
      border: 1.9px solid;
    }
  `,
}
