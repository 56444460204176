import Link from 'next/link'
import Image from 'next/image'
import { AppBar, Toolbar } from '@mui/material'
import { css, Theme } from '@emotion/react'

import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'

type Props = {
  darkState: boolean
  handleThemeChange: () => void
}

const Header: React.FC<Props> = ({ darkState, handleThemeChange }) => {
  const links = [
    { label: 'SERVICE', href: '/#service', hasDropdown: true },
    { label: 'WORKS', href: '/works', hasDropdown: false },
    { label: 'BLOG', href: '/blog', hasDropdown: false },
    { label: 'NEWS', href: '/news', hasDropdown: false },
  ].map(({ label, href, hasDropdown }) => {
    return (
      <div key={label}>
        <li className={hasDropdown ? 'has_dropdown' : null}>
          <Link href={href} passHref>
            <a css={styles.menuItem}>
              <span>{label}</span>
            </a>
          </Link>
          {hasDropdown ? (
            <ul css={styles.dropdown}>
              <li css={styles.dropdown_item}>
                <Link href='/advertising' passHref>
                  <a css={styles.droplink}>
                    <div css={styles.imgContainer}>
                      <Image
                        src='/ad_hero.svg'
                        priority
                        layout='fill'
                        objectFit='cover'
                        css={styles.img}
                      />
                      <h2 css={styles.imgtitle}>
                        ウェブ広告運用支援
                        <br />
                        Advertising Management
                      </h2>
                    </div>
                  </a>
                </Link>
              </li>
              <li css={styles.dropdown_item}>
                <Link href='/creative' passHref>
                  <a css={styles.droplink}>
                    <div css={styles.imgContainer}>
                      <Image
                        src='/creative_hero.svg'
                        layout='fill'
                        objectFit='cover'
                        css={styles.img}
                      />
                      <h2 css={styles.imgtitle}>
                        サイト・LP制作支援
                        <br />
                        Website/LP
                      </h2>
                    </div>
                  </a>
                </Link>
              </li>
            </ul>
          ) : null}
        </li>
      </div>
    )
  })

  return (
    <AppBar
      color='transparent'
      position='fixed'
      elevation={0}
      css={styles.container}
      component='nav'>
      <Toolbar>
        <Link href='/' passHref>
          <a>
            <div css={styles.logoContainer}>
              {darkState ? (
                <Image src='/logo_hd4.webp' layout='fill' objectFit='cover' />
              ) : (
                <Image src='/logo_hl4.webp' layout='fill' objectFit='cover' />
              )}
            </div>
          </a>
        </Link>
        <div css={styles.grow} />
        <ul css={styles.list}>{links}</ul>
        {darkState ? (
          <Brightness7Icon
            css={styles.Icon}
            fontSize='large'
            color='primary'
            onClick={handleThemeChange}
          />
        ) : (
          <Brightness4Icon
            css={styles.Icon}
            fontSize='large'
            onClick={handleThemeChange}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}

const styles = {
  container: css`
    padding: 10px 20px 0 0;
    height: 80px;
    background: transparent;
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4); */
    @media (max-width: 768px) {
      height: 70px;
      padding-left: 0;
      padding-right: 45px;
      background: transparent;
      box-shadow: none;
    }
  `,
  logoContainer: css`
    position: relative;
    width: 50px;
    height: auto;
    padding: 20px 60px;
    cursor: pointer;
    @media (min-width: 960px) {
      width: 80px;
    }
  `,
  list: css`
    list-style: none;
    display: flex;
    justify-content: flex-start;
    & .has_dropdown:hover ul {
      visibility: visible;
      opacity: 1;
    }
  `,
  menuItem: (theme: Theme) => css`
    span {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 1.1rem;
      color: ${theme.palette.primary.main};
      margin-right: 3vw;
      cursor: pointer;
      position: relative;
    }
    span:after {
      z-index: 10;
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.3rem;
      width: 0%;
      height: 2px;
      background: ${theme.palette.info.main};
      transition: all 0.4s ease;
    }
    &:hover span:after {
      width: 100%;
    }
    @media (max-width: 960px) {
      display: none;
    }
  `,
  dropdown: css`
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    list-style: none;
    background-color: white;
    display: flex;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;
    padding: 100px 30px 0;
    padding-inline-start: 0;
    width: 100vw;
    top: -10px;
    left: 0;
    transition: all 0.4s ease-in-out;
  `,
  dropdown_item: css`
    width: 450px;
    height: 280px;
    padding: 20px;
  `,
  droplink: (theme: Theme) => css`
    color: ${theme.palette.primary.main};
  `,
  imgContainer: css`
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    &:before {
      z-index: 10;
      background-color: rgba(0, 0, 10, 0.4);
      transition: all 0.5s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
    &:hover:before {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &:hover img {
      transform: scale(1.1);
    }
  `,
  img: css`
    transition: all 0.5s ease-in-out;
  `,
  imgtitle: css`
    position: absolute;
    z-index: 12;
    width: 100%;
    height: 60px;
    left: 50%;
    bottom: 10px;
    color: white;
    text-align: center;
    transform: translateX(-50%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
  `,
  grow: css`
    flex-grow: 1;
  `,
  Icon: css`
    opacity: 0.8;
  `,
}

export default Header
