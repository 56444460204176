import { useState, useEffect } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'

import { ApolloProvider } from '@apollo/client'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ThemeProvider } from '@mui/material'

import { BGMenuProvider } from '~/context/BGMenuContext'
import { LoadingProvider } from '~/context/LoadingContext'
import Layout from '~/components/organisms/Layout'

import { useApollo } from '~/lib/apollo'
import { GTMPageView } from '~/lib/gtm'
import { setActive } from '~/utils/setActive'
import createEmotionCache from '~/utils/createEmotionCache'
import { FontAwesome } from '~/lib/fontAwesome'
import { GoogleTagManager } from '~/lib/gtm'

import { globalStyles } from '~/styles/styles'
import { themeDark, themeLight } from '~/styles/theme'

const clientSideEmotionCache = createEmotionCache()

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER_ID
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const MyApp = (props: MyAppProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props
  const [darkState, setDarkState] = useState(false)
  const handleThemeChange = () => {
    setDarkState(!darkState)
  }

  const client = useApollo(pageProps)

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    setActive()

    const handleRouteChange = (url: string) => GTMPageView(url)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name='viewport' content='width=device-width,initial-scale=1' />
          <link rel='shortcut icon' href='/favicon.ico' />
          <link rel='apple-touch-icon' href='/apple-touch-icon.png' />
          <link rel='icon' href='/android-chrome-192x192.png' />
        </Head>
        <FontAwesome />
        <GoogleTagManager googleTagManagerId={googleTagManagerId} />
        <ApolloProvider client={client}>
          <ThemeProvider theme={darkState ? themeDark : themeLight}>
            <BGMenuProvider>
              <LoadingProvider>
                <Layout
                  darkState={darkState}
                  handleThemeChange={handleThemeChange}>
                  {globalStyles}
                  <Component {...pageProps} />
                </Layout>
              </LoadingProvider>
            </BGMenuProvider>
          </ThemeProvider>
        </ApolloProvider>
      </CacheProvider>
    </>
  )
}

export default MyApp
