import blue from '@mui/material/colors/blue'
import red from '@mui/material/colors/red'
import { createTheme } from '@mui/material/styles'
import { jaJP } from '@mui/material/locale'

export const themeDark = createTheme(
  {
    palette: {
      primary: { main: '#ffffff', dark: '#cccccc', light: '#807d7d' },
      secondary: { main: '#1a1a1a' },
      info: { main: blue[300], dark: blue[800] },
      warning: { main: red[200] },
      error: { main: red[400] },
      background: { default: '#0c0716', paper: '#ffffff' },
      mode: 'dark',
    },
    typography: {
      fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    },
  },
  jaJP
)

export const themeLight = createTheme(
  {
    palette: {
      primary: { main: '#1a1a1a', dark: '#363636', light: '#8b8b8b' },
      secondary: { main: '#fff' },
      info: { main: blue[300], dark: blue[800] },
      warning: { main: red[200] },
      error: { main: red[400] },
      background: { default: '#ffffff', paper: '#1a1a1a' },
      mode: 'light',
    },
    typography: {
      fontFamily: ['Noto Sans', 'sans-serif'].join(','),
      fontWeightBold: 'bold',
    },
  },
  jaJP
)
