import { useContext } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import { Box, List, ListItem, ListItemIcon, Typography } from '@mui/material'

import { BGMenuContext } from '../../context/BGMenuContext'

const Menu = () => {
  const ctx = useContext(BGMenuContext)

  const list = [
    {
      label: 'HOME',
      url: '/',
      icon: 'home',
    },
    {
      label: 'SERVICE',
      url: '/#service',
      icon: 'ad',
    },
    {
      label: 'WORKS',
      url: '/works',
      icon: 'archive',
    },
    {
      label: 'BLOG',
      url: '/blog',
      icon: 'blog',
    },
    {
      label: 'NEWS',
      url: '/news',
      icon: 'rss-square',
    },
    {
      label: 'CONTACT',
      url: '/form',
      icon: 'envelope-open-text',
    },
  ]

  return (
    <Box
      component='nav'
      aria-expanded={ctx.isMenuOpen}
      css={styles.NavContainer}>
      <List component='ul'>
        {list.map(({ label, url, icon }) => {
          return (
            <ListItem
              css={styles.listWrapper}
              key={label}
              onClick={() => ctx.toggleMenu()}>
              <Link href={url} aria-label={label} passHref>
                <a css={styles.link}>
                  <div css={styles.innerList}>
                    <ListItemIcon>
                      <i
                        aria-hidden
                        className={`fas fa-${icon}`}
                        css={styles.navIcon}
                      />
                    </ListItemIcon>
                    <Typography css={styles.ListText}>{label}</Typography>
                  </div>
                </a>
              </Link>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

const styles = {
  NavContainer: css`
    position: fixed;
    z-index: 1200;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-bottom: 10vh;
    background-color: rgba(6, 6, 11, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    &[aria-expanded='true'] {
      visibility: visible;
      opacity: 1;
    }
  `,
  listWrapper: css`
    list-style: none;
  `,
  link: css`
    text-decoration: none;
  `,
  innerList: css`
    display: flex;
    align-items: center;
    cursor: pointer;
  `,
  ListText: css`
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 2.8rem;
    font-weight: bold;
  `,
  navIcon: css`
    color: white;
    font-size: 2rem;
  `,
}

export default Menu
