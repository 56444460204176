import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { css, Theme } from '@emotion/react'
import {
  Typography,
  Container,
  Collapse,
  Box,
  List,
  ListItem,
} from '@mui/material'
import { PlayCircleOutline } from '@mui/icons-material'

type Links = {
  label: string
  href: string
}[]

type Props = {
  darkState: boolean
}

const serviceLinks: Links = [
  { label: 'ウェブ広告運用', href: '/advertising' },
  { label: 'サイト・LP制作', href: '/creative' },
]

const systemLinks: Links = [
  { label: '料金・プラン', href: '/price' },
  { label: '依頼の流れ', href: '/flow' },
  { label: 'よくある質問', href: '/faq' },
  { label: 'お問い合わせ', href: '/form' },
]

const Footer: React.FC<Props> = ({ darkState }) => {
  const [mobile, setMobile] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [openTwo, setOpenTwo] = useState(false)

  useEffect(() => {
    const updateMobile = () => {
      setMobile(window.innerWidth < 1000 ? true : false)
    }

    updateMobile()
    window.addEventListener('resize', updateMobile)
    return () => {
      window.removeEventListener('resize', updateMobile)
    }
  }, [])

  const listItems = (links: Links): JSX.Element[] => {
    return links.map(({ label, href }) => {
      return (
        <ListItem key={label} css={styles.f_listItem}>
          <Link href={href} passHref>
            <a css={styles.f_listText}>
              <span>{label}</span>
            </a>
          </Link>
        </ListItem>
      )
    })
  }

  return (
    <footer css={styles.wrapper}>
      <Container maxWidth='lg' disableGutters css={styles.container}>
        <Box css={styles.footer}>
          <Box css={styles.logoContainer}>
            <div css={styles.logo}>
              <Link href='/'>
                <a>
                  {darkState ? (
                    <Image
                      src='/logo_sqd.png'
                      layout='intrinsic'
                      height={300}
                      width={300}
                    />
                  ) : (
                    <Image
                      src='/logo_sql.png'
                      layout='intrinsic'
                      height={300}
                      width={300}
                    />
                  )}
                </a>
              </Link>
            </div>
          </Box>
          <Box css={styles.menuContainer} component='nav'>
            <Box css={styles.menu}>
              <div
                css={styles.titleContainer}
                onClick={() => setOpen(!open)}
                data-type={open ? 'isActive' : null}>
                <PlayCircleOutline css={styles.icon} color='primary' />
                <Typography
                  component='p'
                  variant='h6'
                  css={styles.listTitle}
                  color='primary'>
                  SERVICE
                </Typography>
              </div>
              <Collapse in={mobile ? open : true} timeout='auto' unmountOnExit>
                <List component='ul'>{listItems(serviceLinks)}</List>
              </Collapse>
            </Box>
            <Box css={styles.menu}>
              <div
                css={styles.titleContainer}
                onClick={() => setOpenTwo(!openTwo)}
                data-type={openTwo ? 'isActive' : null}>
                <PlayCircleOutline css={styles.icon} color='primary' />
                <Typography
                  component='p'
                  variant='h6'
                  css={styles.listTitle}
                  color='primary'>
                  SYSTEM
                </Typography>
              </div>
              <Collapse
                in={mobile ? openTwo : true}
                timeout='auto'
                unmountOnExit>
                <List component='ul'>{listItems(systemLinks)}</List>
              </Collapse>
            </Box>
            <Box css={styles.menu}>
              <Link href='/works' passHref>
                <a css={styles.menuHeader}>
                  <div css={styles.titleContainer2}>
                    <PlayCircleOutline css={styles.icon} />
                    <Typography
                      component='p'
                      variant='h6'
                      css={styles.listTitle}>
                      WORKS
                    </Typography>
                  </div>
                </a>
              </Link>
              <Link href='/blog' passHref>
                <a css={styles.menuHeader}>
                  <div css={styles.titleContainer2}>
                    <PlayCircleOutline css={styles.icon} />
                    <Typography
                      component='p'
                      variant='h6'
                      css={styles.listTitle}>
                      BLOG
                    </Typography>
                  </div>
                </a>
              </Link>
              <Link href='/news' passHref>
                <a css={styles.menuHeader}>
                  <div css={styles.titleContainer2}>
                    <PlayCircleOutline css={styles.icon} />
                    <Typography
                      component='p'
                      variant='h6'
                      css={styles.listTitle}>
                      NEWS
                    </Typography>
                  </div>
                </a>
              </Link>
              <Link href='/about' passHref>
                <a css={styles.menuHeader}>
                  <div css={styles.titleContainer2}>
                    <PlayCircleOutline css={styles.icon} />
                    <Typography
                      component='p'
                      variant='h6'
                      css={styles.listTitle}>
                      ABOUT
                    </Typography>
                  </div>
                </a>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box css={styles.footerAbout}>
          <Box>
            <Link
              href='https://twitter.com/edu_ad_official?ref_src=twsrc%5Etfw'
              passHref>
              <a>
                <div css={styles.twitter}>
                  <span></span>
                </div>
              </a>
            </Link>
            <Typography variant='body2' component='small' color='primary'>
              ©️2021 eduad. All Rights Reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </footer>
  )
}

const styles = {
  wrapper: css`
    height: 40vh;
    @media (max-width: 960px) {
      height: auto;
      padding-bottom: 60px;
    }
  `,
  container: css``,
  footer: css`
    display: flex;
    padding: 50px 0 0;
    @media (max-width: 960px) {
      flex-flow: column;
      padding: 50px 20px;
    }
  `,
  logoContainer: css`
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 150px;
    @media (max-width: 960px) {
      width: 100%;
      padding-left: 0;
      margin-bottom: 40px;
    }
  `,
  logo: css`
    width: 200px;
    height: 200px;
  `,
  menuContainer: css`
    display: flex;
    width: 70%;
    padding-left: 8vw;
    justify-content: space-between;
    @media (max-width: 960px) {
      flex-flow: column;
      width: 100%;
      padding: 0;
    }
  `,
  menu: css`
    flex-basis: 100%;
    margin-right: 3rem;
    @media (max-width: 960px) {
      margin-right: 0;
    }
  `,
  titleContainer: (theme: Theme) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 0.7px solid ${theme.palette.primary.main};
    @media (max-width: 960px) {
      cursor: pointer;
      &:after {
        content: '\\f078';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: ${theme.palette.primary.main};
        position: absolute;
        right: 10px;
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &[data-type='isActive']:after {
        transform: rotate(180deg);
      }
    }
  `,
  titleContainer2: css`
    height: 25%;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 0.7px solid;
  `,
  listTitle: css`
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.1rem;
    padding: 1rem 0;
    flex-grow: 1;
  `,
  icon: css`
    opacity: 0.8;
    margin-right: 0.8rem;
  `,
  f_listItem: css`
    a:before {
      content: '-';
      width: 0.7rem;
      margin-right: 0.7rem;
    }
    &:hover a:before {
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }
  `,
  f_listText: (theme: Theme) => css`
    opacity: 0.7;
    font-size: 0.7rem;
    font-family: 'Noto sans JP', sans-serif;
    font-weight: 500;
    color: ${theme.palette.primary.main};
    cursor: pointer;
    :hover {
      opacity: 1;
      transition: color 0.4s ease-in-out;
    }
    span {
      position: relative;
    }
    span:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.2rem;
      width: 0%;
      height: 0.5px;
      background: ${theme.palette.primary.main};
      transition: all 0.4s;
    }
    &:hover span:after {
      width: 100%;
    }
    @media (max-width: 1000px) {
      font-size: 1rem;
    }
  `,
  menuHeader: (theme: Theme) => css`
    text-decoration: none;
    color: ${theme.palette.primary.main};
  `,
  footerAbout: css`
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    @media (max-width: 960px) {
      margin-bottom: 0;
      padding-bottom: 30px;
    }
  `,
  twitter: css`
    height: 50px;
    & span:after {
      cursor: pointer;
      content: '';
      background: url('/twitter.svg');
      position: absolute;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: 30px 0;
      transform: translate(-50%, -50%);
      font-size: 3rem;
    }
  `,
  addressContainer: css`
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 960px) {
      flex-flow: column;
    }
  `,
  address: css`
    font-family: 'Noto sans JP', sans-serif;
    font-size: 1vw;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    @media (max-width: 960px) {
      width: 60vw;
      font-size: 0.8rem;
      font-weight: 700;
      padding-bottom: 0.4rem;
    }
  `,
}

export default Footer
