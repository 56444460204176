import React, { useState } from 'react'

interface ContextProps {
  isTriggered: boolean
  toggleTrigger: () => void
}
// make a new context
export const LoadingContext = React.createContext({} as ContextProps)

// create the provider
export const LoadingProvider = ({ children }) => {
  const [loadingState, setLoadingState] = useState(false)

  return (
    <LoadingContext.Provider
      value={{
        isTriggered: loadingState,
        toggleTrigger: () => setLoadingState(true),
      }}>
      {children}
    </LoadingContext.Provider>
  )
}
