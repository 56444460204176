import { useRouter } from 'next/router'

import Header from './Header'
import Footer from './Footer'
import Contact from './Contact'
import { BGMenuButton } from '~/components/atoms/BGMenuButton'
import { SideButton } from '../atoms/SideButton'
import Menu from './Menu'

type Props = {
  darkState: boolean
  handleThemeChange: () => void
}

const Layout: React.FC<Props> = ({
  children,
  darkState,
  handleThemeChange,
}) => {
  const router = useRouter()
  return (
    <>
      <header>
        <Header darkState={darkState} handleThemeChange={handleThemeChange} />
        <BGMenuButton />
        <Menu />
        {router.pathname == '/form' || router.pathname == '/thanks' ? null : (
          <SideButton />
        )}
      </header>
      {children}
      {router.pathname == '/form' || router.pathname == '/thanks' ? null : (
        <Contact />
      )}
      <Footer darkState={darkState} />
    </>
  )
}

export default Layout
