import { css, Global, Theme } from '@emotion/react'

export const globalStyles = (
  <Global
    styles={(theme: Theme) => css`
      html,
      body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        background-color: ${theme.palette.background.default};
        #menu- {
          .MuiPopover-paper {
            background-color: ${theme.palette.secondary.main};
          }
        }
      }
      * {
        text-decoration: none;
        padding-inline-start: 0;
        font-family: 'Noto sans JP', sans-serif;
      }
      .no-scroll {
        overflow-y: hidden;
        touch-action: none;
      }
    `}
  />
)
