import Image from 'next/image'
import { css, keyframes } from '@emotion/react'
import { Box, Typography } from '@mui/material'

import { CVButton } from '~/components/atoms/CVButton'

import ContactCover from 'public/contact.webp'

const Contact = () => {
  return (
    <section css={styles.wrapper}>
      <Box css={styles.contactBox}>
        <Typography variant='h1' css={styles.header}>
          CONTACT
        </Typography>
        <Typography variant='h1' css={styles.message}>
          学習塾・教室業のためのウェブ広告や
          <br />
          サイト・LP制作ならeduad。
          <br />
          まずは、無料個別相談へお申込ください。
        </Typography>
        <CVButton
          url='/form'
          label='お見積・無料相談はこちら'
          micro='＼塾・教室業のウェブ集客にはコツがあります／'
        />
      </Box>
      <Box css={styles.image}>
        <Image src={ContactCover} layout='fill' objectFit='cover' />
      </Box>
    </section>
  )
}

const styles = {
  wrapper: css`
    color: black;
    height: 40vh;
    min-height: 400px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 960px) {
      height: 50vh;
      min-height: 600px;
    }
  `,
  contactBox: css`
    z-index: 100;
    position: absolute;
    top: 10%;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    @media (min-width: 960px) {
      width: 60vw;
    }
  `,
  header: css`
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    font-weight: 700;
    @media (min-width: 960px) {
      font-size: 4rem;
    }
  `,
  message: css`
    font-family: 'Noto sans JP', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 50px 10px 50px;
    text-align: center;
    @media (min-width: 960px) {
      font-size: 1.5rem;
      padding: 100px 0 50px;
    }
  `,
  image: css`
    position: relative;
    width: 100%;
    height: 100%;
    &:before {
      z-index: 50;
      background-color: rgba(255, 255, 255, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  `,
}

export default Contact
